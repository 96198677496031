<template>
  <div
      class="recent-drops-card"
      @click="goClick"
  >
    <img
        :src=ArtworkImg
        alt="drop-img"
    />
    <div
        class="drop-rating"
        :class="{'drop-rating-diamond': tier === 'DIAMOND', 'drop-rating-platinum' : tier === 'PLATINUM', 'drop-rating-gold' : tier === 'GOLD'}">
      {{ Tier }}
    </div>
    <div class="drops-recent-explain">
      <div>
        <v-avatar>
          <img
              :src=AvatarImg
              alt="avatar-img"
          >
        </v-avatar>
        <div class="drop-info">
          <p>{{ UserName.length === 42 ? reduceAddress(UserName) : UserName }}</p>
          <p>Collector</p>
        </div>
      </div>
      <div class="dots-vertical">
        <v-menu
            top
            :offset-y="offset"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs"
                    v-on="on">mdi-dots-vertical
            </v-icon>
          </template>

          <v-list>
            <v-list-item
                v-for="(item, index) in ellipsisItems"
                :key="index"
                @click="goPage(item.name)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div class="drops-recent-explain explain-background">
      <div class="drop-info">
        <p>{{ EditionTitle }}</p>
        <p>Edition</p>
      </div>
      <div class="drop-info">
        <p>{{ EditionType }}</p>
        <p>1 song</p>
      </div>
      <div class="drop-info">
        <p>1/{{ MaxSupply }}</p>
        <p>Token No.</p>
      </div>
    </div>
  </div>
</template>
<script>
import {reactive, toRefs, computed} from '@vue/composition-api'

export default {
  components: {},
  props: {
    artworkImg: {type: String, default: ''},
    tier: {type: String, default: ''},
    avatarImg: {
      type: Object, default: () => {
        return {
          avatarAsset: null,
        }
      }
    },
    userName: {type: String, default: ''},
    editionTitle: {type: String, default: ''},
    editionType: {type: String, default: ''},
    maxSupply: {type: Number, default: 0},
    artist: {type: String, default: ''},
  },
  setup(props) {
    const state = reactive({
      Tier: computed(() => props.tier),
      ArtworkImg: computed(() => `https://royal-io.imgix.net/${props.artworkImg}`),
      AvatarImg: computed(() => props.avatarImg ? `https://royal-io.imgix.net/${props.avatarImg.filename}` : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ5ynbgJy2hzqlN4PKwb6-te9VvZ0yTx3sVQ&usqp=CAU'),
      UserName: computed(() => props.userName),
      EditionTitle: computed(() => props.editionTitle),
      EditionType: computed(() => props.editionType),
      MaxSupply: computed(() => props.maxSupply)
    })

    const reduceAddress = (value) => {
      return value.slice(0, 6) + '...' + value.slice(38, 42)
    }

    return {...toRefs(state), reduceAddress }
  },
  data() {
    return {
      ellipsisItems: [
        {title: 'VISIT ARTIST PAGE', name: 'artist'},
        {title: 'VISIT EDITION PAGE', name: 'edition'},
      ],
      offset: true,
    }
  },
  methods: {
    goClick() {
      this.$emit('click')
    },
    goPage(name) {
      this.$router.push({
        name: name,
        params: {
          artist: this.artist,
          edition: this.editionTitle
        }
      })
    },
  },
}
</script>
